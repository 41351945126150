.reviews{
    display: grid;
    grid-template-columns: repeat(6,1fr);

}
.reviews img{
    cursor: pointer;
}
.reviews-section{
    grid-column: 2/ span 4;
}
.slick-prev, .slick-next{
    display: none !important;
}

.reviews-section p:nth-child(2n+3){
  font-style: italic;
  font-family: "Product Sans Italic",sans-serif;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .reviews{
        display: grid;
        grid-template-columns: repeat(8,1fr);
    
    }
    .reviews-section{
        grid-column: 2/ span 6;
    }
}
