
.arrow {
    position: absolute;
    right:48%;
   transform: translate(20%, -20%);
   
}

@media (min-width : 320px) and (max-width : 640px) {
    .arrow{
        right: 5%;
        transform: translate(-20%, -10%);
    }
}