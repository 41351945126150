@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  
}

:root{
  --blue: #142945;
  --gold:rgba(203, 163, 97, 1);
  --dark-gold:rgba(221, 171, 62, 0.5);
  --black:rgba(0, 0, 0, 1);
  --white: rgba(255, 255, 255, 0.65);
  --sky-blue:rgba(214, 231, 255, 1);
  --radial-gold: rgba(255, 165, 0, 0.05);
  --light-gold:  radial-gradient(circle, rgba(255,165,0,0) 0%, rgba(255,165,0,0.05) 100%);
  --radial-pink:radial-gradient(50% 50% at 50% 50%, #C53B00 0%, #FFCAB4 100%);
  --radial-green:radial-gradient(64.91% 64.91% at 58.13% 35.09%, #FFE373 0%, #438A59 100%);
  --radial-blue:radial-gradient(100.74% 73.65% at 57.47% 26.35%, #C0D9FF 0%, rgba(0, 102, 255, 0.62) 100%);
  --light-radial-green:radial-gradient(100.74% 73.65% at 57.47% 26.35%, #C0D9FF 0%, rgba(0, 102, 255, 0.62) 100%); 
  --light-blue: rgba(214, 231, 255, 1);
  --grey: #707070;
  --lightgrey:#D7D7D7;

  --step--5: clamp(0.52rem, calc(0.28rem + 1.18vw), 1.04rem);
  --step--4: clamp(0.59rem, calc(0.32rem + 1.33vw), 1.17rem);
  --step--3: clamp(0.66rem, calc(0.36rem + 1.50vw), 1.32rem);
  --step--2: clamp(0.74rem, calc(0.40rem + 1.68vw), 1.48rem);
  --step--1: clamp(0.83rem, calc(0.45rem + 1.89vw), 1.67rem);
  --step-0: clamp(0.94rem, calc(0.51rem + 2.13vw), 1.88rem);
  --step-1: clamp(1.06rem, calc(0.58rem + 2.40vw), 2.11rem);
  --step-2: clamp(1.19rem, calc(0.65rem + 2.70vw), 2.37rem);
  --step-3: clamp(1.34rem, calc(0.73rem + 3.03vw), 2.67rem);
  --step-4: clamp(1.50rem, calc(0.82rem + 3.41vw), 3.00rem);
  --step-5: clamp(1.69rem, calc(0.92rem + 3.84vw), 3.38rem);
  --step-6: clamp(1.90rem, calc(1.04rem + 4.32vw), 3.80rem);
  --image-scale:clamp();

}
@font-face {
  font-family: 'Product Sans Bold';
  src:  url('./public/fonts/ProductSans-Bold.ttf') format('truetype');
        
}
@font-face {
  font-family: 'Product Sans Italic';
  src:  url('./public/fonts/ProductSans-Italic.ttf') format('truetype');
        
}
@font-face {
  font-family: 'Product Sans Black';
  src:  url('./public/fonts/ProductSans-Black.ttf') format('truetype');
        
}
@font-face {
  font-family: 'Product Sans ';
  src:  url('./public/fonts/ProductSans-Regular.ttf') format('truetype');
        
}
@font-face {
  font-family: 'Product Sans Light ';
  src:  url('./public/fonts/ProductSans-Light.ttf') format('truetype');
        
}

.hero{
  background: url('./public/images/hero.png');
  background-position: center;
  
}
html{
  font-size: 16px;
}


h1,h2{
  font-family:"Product Sans Black",sans-serif;
  font-weight: 900;
}
h3{
  
  font-family:"Product Sans ",sans-serif;
  font-weight: 700;
  
}
.typewriter{
  font-family:"Product Sans  ",sans-serif;
  font-weight: 700;
}
 a,span{
  font-family:"Product Sans ",sans-serif;
  text-decoration: none;
  font-weight: 400;
}

p{
  
  font-family:"Product Sans ",sans-serif;
  color: var(--grey);
  font-weight: 400;
  
}
img{
  max-width: 100%;
  height: auto;
}
.container-center{
  max-width: 89%;
  margin: 0 auto;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

button:hover{
  cursor: pointer;
}