.grid-card{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(100%/2),1fr));
    gap: 2rem 2rem;
    padding:5em 0;
    

}
.services{
    background:url('../public//images/Our\ services\ \(2\).png')
}
.service-card{
   min-width:300px ;
}

@media  (min-width:640px) {
 .grid-card{
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
 }
}