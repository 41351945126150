
.sidebar-navigation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    background-color: var(--blue);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    
    }
    
    .sidebar-navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
    }
    
    .sidebar-navigation li {
    padding: 10px;
    font-size: 18px;
    }
    
    .sidebar-navigation li:hover {
    background-color: #f1f1f1;
    cursor: pointer;
    }
.sidebar{
    z-index: 999px;
}